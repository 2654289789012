import juanda_image from '../../assets/about-us/001_the_juanda.png'
import cemandi_image from '../../assets/about-us/002_the_cemandi.png'
import fortune_image from '../../assets/about-us/003_the_fortune.png'
import juanda_logo from '../../assets/about-us/001_the_juanda_logo.png'
import juanda_new from '../../assets/about-us/001_the_juanda_new.png'
import cemandi_logo from '../../assets/about-us/002_the_cemandi_logo.png'
import fortune_logo from '../../assets/about-us/003_the_fortune_logo.png'
import gozco_office from '../../assets/about-us/004_gozco_capital_office.jpg'
import juandaland from '../../assets/about-us/004_juandaland.png'
import juandaland_logo from '../../assets/about-us/004_juanda_land_logo.png'
import juandaland_logo_white from '../../assets/about-us/004_juanda_land_logo_white.png'
import surya_logo from '../../assets/about-us/001.png'
import gozco_logo from '../../assets/Gozco_Land.png'


import icon from '../../assets/footer.png'
// import slides_001 from '../../assets/about-us/sliders/00 (1).png'
// import slides_002 from '../../assets/about-us/sliders/00 (2).png'
// import slides_003 from '../../assets/about-us/sliders/00 (3).png'
// import slides_004 from '../../assets/about-us/sliders/00 (4).png'
// import slides_005 from '../../assets/about-us/sliders/00 (5).png'
// import slides_006 from '../../assets/about-us/sliders/00 (6).png'
// import slides_007 from '../../assets/about-us/sliders/00 (7).png'
// import slides_008 from '../../assets/about-us/sliders/00 (8).png'

const about_us = '<b>GEDANGAN INDUSTRIAL PARK (GIP)</b> merupakan pengembangan terbaru sebuah kawasan perindustrian yang telah dikenal di Selatan Surabaya (Gedangan). Lokasi yang terdepan, selangkah dari Flyover Djuanda yang dirancang untuk mengurai kemacetan, sehingga membuat dampak yang sangat positif untuk pengembangan bisnis anda di masa yang akan datang.<br /><br />Sebagai pusat perkembangan industri baru dan terpadu, GIP akan mengembangkan unit terbaru dengan spesifikasi terbaik di kelasnya, serta infrastruktur yang mendukung untuk perkembangan bisnis dan industri Anda.<br />#GrowWithUs'

// const history = `Perseroan didirikan dengan nama PT Surya Intrindo Makmur (selanjutnya disebut Perseroan)<br /><br />Berdasarkan Akta Pendirian No: 48 tanggal 29 Juli 1996, oleh Notaris Ny. Nursetiani Budi, yang telah memperoleh pengesahan Mentri Kehakiman Republik Indonesia No. C2-10432.HT.01.01.TH 96 tanggal 19 November 1996. Perubahan dengan Akta No. 9 tanggal 28 Juni 2001 dari Zainal Arifin SE. SH., notaris pengganti dari Johan Sidharta, SH., MS, notaris di Surabaya, mengenai pemecahan nilai nominal saham (stock split) dari Rp. 500 menjadi Rp. 100 per lembar saham dan telah mendapatkan pengesahan dari menteri Kehakiman dan Hak Asasi Manusia Republik Indonesia No. C-04052.HT.01.04. th.2001 tanggal 30 Juli 2001. Kegiatan usaha Perseroan dan Anak Perseroan adalah bergerak dibidang Industri alas kaki. Penjualan sepenuhnya ditujukan ke pasar ekspor terutama ke Negara Amerika, Eropa dan Kanada.<br /><br />Perubahan akte terakhir berdasar Akta Notaris No.6 tanggal 26 Juni 2018 dari Notaris Wachid Hasyim, SH, Surabaya, tentang susunan anggota Direksi dan Komisaris, dan telah mendapatkan pengesahan dari menteri Kehakiman dan Hak Asasi Manusia Republik Indonesia No. AHU-AH.01.03-0226088 tanggal 26 Juli 2018.`

const history = "Perseroan didirikan dengan nama PT Surya Intrindo Makmur (selanjutnya disebut Perseroan) yang merupakan bagian dari Gozco Group<br><br>Berdasarkan perjanjian kerjasama operasi nomor 7 tanggal 25 Januari 2023, Grup membentuk kerjasama operasi dengan PT Benteng Tunggal dengan nama Kerjasama Operasi Gedangan Industrial Park atau disebut KSO GIP. Kerjasama operasi merupakan pekerjaan dan kegiatan untuk perencanaan dan pembangunan serta pengelolaan dan penjualan properti beserta sarana dan prasarananya dan pembangunan serta pengelolaan dan penjualan properti beserta sarana dan prasarananya yang berdiri diatas tanah 14.468 m2 yang tercantum dalam peta bidang nomor 50-12.10-2017 terletak di provinsi Jawa Timur kabupaten Sidoarjo Kecamatan Taman Desa Bohar"

const slider_img = [
	icon,
	icon,
	icon,
	icon,
	icon,
	icon,
]

const gozco_land = "As the fourth most populous country in the world, Indonesia's need for property is constantly increasing. Gozco Land commits to actively expand its landbanks and develop them to cater to this demand which will contribute to the overall development of the country."
const vision = "To elevate the standard of living for the better well-being of the people."
const mission = "To bring new inspiration for the way of living."


export {
	about_us,
	gozco_land,
	vision,
	mission,
	juanda_image,
	juanda_new,
	cemandi_image,
	fortune_image,
	gozco_office,
	juanda_logo, 
	cemandi_logo,
	fortune_logo,
	gozco_logo,
	slider_img,
	juandaland,
	juandaland_logo,
	juandaland_logo_white,
	history,
	surya_logo
}
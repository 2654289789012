import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { useLocation } from 'react-router-dom'

import icon from '../../assets/footer.png'

export default function Navbar(props) {
	var base = new Base()

	const [header_nav_arr] = useState([
		{ title: 'Home', url: '/' },
		{ title: 'Unit', url: '/unit' },
		{ title: 'News', url: '/news' },
		// {
		// 	title: 'News', url: '#', menu_arr: [
		// 		{ title: 'News Grid', url: 'news-grid.html' },
		// 		{ title: 'News Listing', url: 'news-listing.html' },
		// 		{ title: 'News Sidebar', url: 'news-sidebar.html' },
		// 	]
		// },
		{ title: 'About', url: '/about-us' },
		{ title: 'Contact', url: '/contact-us' },
	])
	const [scrollY, setScrollY] = useState(-1)
	const [navbarBackgroundColor, setNavbarBackgroundColor] = useState('#152C5D')
	const [isChangeStyle, setIsChangeStyle] = useState(false)
	let { pathname, search } = useLocation()

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		console.log(pathname)
	}, [])

	useEffect(() => {
		// console.log(props.scrollY)
		if (pathname == "/unit") {
			setNavbarBackgroundColor("#152C5D")
		} else{
			setIsChangeStyle(scrollY <= base.responsive_scroll_threshold(600))
			setNavbarBackgroundColor(scrollY <= base.responsive_scroll_threshold(600) ? '#00000000' : '#152C5D')
		}
	}, [scrollY])

	function handleScroll(){
		// console.log('test')
		setScrollY(window.scrollY)
	}

	return (
		<>
			<header className="stick v2" style={{backgroundColor: navbarBackgroundColor, padding: pathname == "/unit" && 0}}>
				<div className="hd_bar">
					<div className={"header-bar qut-style p-0"} style={{ backgroundColor: navbarBackgroundColor, transition: 'background-color 1s', boxShadow: !isChangeStyle ? '0 .5rem 1rem rgba(0,0,0,.15)' : 'none' }}>
						<div className="container" >
							<div className='row'>
								{/* <div className="logo d-none d-lg-block" style={{ width: 'auto', }}>
									
								</div> */}
								<div className="col-lg-3 p-0 d-none d-lg-block" style={{  }}>
									<div className='d-flex align-items-center h-100'>
										<a href="#" title=""><img src={icon} alt="" height="30rem"/></a>
									</div>
								</div>
								<div className='col-lg-6 p-0 d-none d-lg-block'>
									<div className='d-flex align-items-center justify-content-center h-100'>
										<nav className='d-inline-block' style={{ float: 'none', width: 'auto', }}>
											<ul>
												{
													header_nav_arr.map((data, index) => {
														if(data.menu_arr == null)
															return (
																<li key={index} className={index == 0 ? 'pl-0' : (index == header_nav_arr.length - 1 ? 'pr-0' : '')} style={{ paddingTop: '1rem', paddingBottom: '1rem', }}>
																	<a className={(pathname === data.url ? 'active' : '')}  href={data.url} title="">{data.title}</a>
																</li>
															)
														else
															return (
																<li style={{ paddingTop: '1rem', paddingBottom: '1rem', }}>
																	<a href="#" title="">{data.title}</a>
																	<ul>
																		{
																			data.menu_arr.map((data_menu, index_menu) => (
																				<li key={index_menu}><a href={data_menu.url} title="">{data_menu.title}</a></li>
																			))
																		}
																	</ul>
																</li>
															)
													})
												}
											</ul>
										</nav>
									</div>
								</div>
								<div className='col-6 d-block d-lg-none' style={{ paddingTop: '1rem', paddingBottom: '1rem', }}>
									<div className='d-flex align-items-center h-100' style={{  }}>
										<div className="mobile-menu-btn m-0" style={{ right: 0, float: 'none', zIndex: 10, }}>
											<a href="#" title="" className="open-menu d-flex align-items-center"><i className="fa fa-bars"></i></a>
										</div>
									</div>
								</div>
								<div className='col-6 col-lg-3 p-0'>
									<div className=" get-quote" style={{ width: 'auto', display: 'unset'}}>
										<a href="https://gedanganindustrialpark.com/virtual-tour/" target='__blank' title="" style={{ padding: '1rem 30px', }}>View <br/> Virtual Tour</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>


			<div className="responsive-mobile-menu">
				<a href="#" title="" className="close-menu"><i className="ion-close"></i></a>
				<ul>
					{
						header_nav_arr.map((data, index) => {
							if(data.menu_arr == null)
								return (
									<li key={index}><a className={(pathname === data.url ? 'active' : '')} href={data.url} title="">{data.title}</a></li>
								)
							else
								return (
									<li><a href="#" title="">{data.title}</a>
										<ul>
											{
												data.menu_arr.map((data_menu, index_menu) => (
													<li key={index_menu}><a href={data_menu.url} title="">{data_menu.title}</a></li>
												))
											}
										</ul>
									</li>
								)
						})
					}
				</ul>
			</div>
		</>
	)
}
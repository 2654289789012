import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { useLocation } from 'react-router-dom'

export default function HomeImage(props) {
	var base = new Base()

	return (
    <div className="gallery-img h-100" style={{ cursor: 'pointer', }} onClick={() => props.onClicked(props.image)}>
      <div className='gradient'></div>
      <img src={props.image} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', }} />
      <div className="gallery-text left-more">
        <h5>{props.title}</h5>
        <h3>{props.content}</h3>
      </div>
    </div>
	)
}
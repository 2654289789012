import tipe01_lt1 from '../../assets/about_us/tipe_01/denah_lt1.png'
import tipe01_lt2 from '../../assets/about_us/tipe_01/denah_lt2.png'
import siteplan01 from '../../assets/about_us/tipe_01/siteplan_01.png'

import icon001 from '../../assets/about_us/Icon_001.png'
import icon002 from '../../assets/about_us/Icon_002.png'
import icon003 from '../../assets/about_us/Icon_003.png'
import icon004 from '../../assets/about_us/Icon_004.png'

import tipe02_lt1 from '../../assets/about_us/tipe_02/tipe02_lt1.png'
import tipe02_lt2 from '../../assets/about_us/tipe_02/tipe02_lt2.png'
import siteplan02 from '../../assets/about_us/tipe_02/siteplan02.png'

import tipe03_lt1 from '../../assets/about_us/tipe_03/tipe03_lt1.png'
import siteplan03 from '../../assets/about_us/tipe_03/siteplan03.png'

import tipe04_lt1 from '../../assets/about_us/tipe_04/tipe04_lt1.png'
import siteplan04 from '../../assets/about_us/tipe_04/siteplan04.png'

import icon_legalitas from '../../assets/Icon_Legalitas_Terjamin.png'

const about_us = '<b>GEDANGAN INDUSTRIAL PARK (GIP)</b> merupakan Pengembangan Terbaru sebuah kawasan perindustrian yang telah dikenal di Selatan Surabaya (Gedangan). Lokasi yang terdepan, Selangkah dari Flyover Aloha yang dirancang untuk mengurai kemacetan, sehingga membuat dampak yang sangat positif untuk pengembangan bisnis anda di masa yang akan datang.<br /><br />Sebagai pusat perkembangan industri baru dan terpadu, GIP akan mengembangkan cluster-cluster terbaru dengan spesifikasi terbaik di kelasnya, fasilitas baru, serta infrastruktur yang mendukung untuk perkembangan bisnis dan industri Anda.<br />#GrowWithUs'

const quote = '"Gedangan Industrial Park terdekat dengan Pusat Kota. Selangkah menuju akses tol yang menghubungkan area Surabaya dan luar Surabaya, juga akses ke Bandara Juanda, pelabuhan, terminal, dan  lain-lain. Aksesnya juga akan semakin mudah dengan dibangunnya flyover Djuanda.”'

const why = {
	'_1': {
		'icon' : icon_legalitas,
		'title1': 'Legalitas',
		'title2': ' Terjamin',
		'desc': 'Legalitas Tanah dan PBG resmi, jelas & terpercaya'
	},
	'_2': {
		'icon' : icon002,
		'title1': 'Lokasi',
		'title2': ' Strategis',
		'desc': 'Selangkah menuju bandara Juanda, akses tol, pusat kota & pelabuhan Tanjung Perak'
	},
	'_3': {
		'icon' : icon003,
		'title1': 'Spesifikasi',
		'title2': ' Terbaik',
		'desc': 'Kapasitas Beban Lantai hingga 3 ton /m². Menggunakan cor beton t:15 cm, wiremesh 8mm'
	},
	'_4': {
		'icon' : icon001,
		'title1': 'Design',
		'title2': ' Modern',
		'desc': 'Berkonsep New American Warehouse'
	},
}

const tipe = {
	'_1': {
		'id': '1',
		'warehouse': {
			'v': '1',
			'p': '12',
			'l': '28',
		},
		'office': {
			'v': '2',
			'p': '6',
			'l': '6'
		},
		'lt': {
			'v': '372-380',
			'p': '12',
			'l': '30',
		},
		'lb': {
			'v': '372',
			'p': '12',
			'l': '28',
		},
		'blok': 'BI',
		'no': '01-06',
		'siteplan': siteplan01,
		'floor1' : tipe01_lt1,
		'floor2' : tipe01_lt2
	},
	'_2': {
		'id': '2',
		'warehouse': {
			'v': '1',
			'p': '12',
			'l': '24',
		},
		'office': {
			'v': '2',
			'p': '6',
			'l': '6'
		},
		'lt': {
			'v': '288',
			'p': '12',
			'l': '24',
		},
		'lb': {
			'v': '312',
			'p': '12',
			'l': '23',
		},
		'blok': 'BH',
		'no': '01-06',
		'siteplan': siteplan02,
		'floor1' : tipe02_lt1,
		'floor2' : tipe02_lt2
	},
	'_3': {
		'id': '3',
		'warehouse': {
			'v': '1',
			'p': '12',
			'l': '28',
		},
		'office': {
			'v': '1',
			'p': '4',
			'l': '3'
		},
		'lt': {
			'v': '348-370',
			'p': '12',
			'l': '30',
		},
		'lb': {
			'v': '336',
			'p': '12',
			'l': '28',
		},
		'blok': 'BI',
		'no': '07-19',
		'siteplan': siteplan03,
		'floor1' : tipe03_lt1,
		'floor2' : null
	},
	'_4': {
		'id': '4',
		'warehouse': {
			'v': '1',
			'p': '12',
			'l': '23',
		},
		'office': {
			'v': '1',
			'p': '4',
			'l': '3'
		},
		'lt': {
			'v': '288',
			'p': '12',
			'l': '24',
		},
		'lb': {
			'v': '276',
			'p': '12',
			'l': '23',
		},
		'blok': 'BH',
		'no': '07-19',
		'siteplan': siteplan04,
		'floor1' : tipe04_lt1,
		'floor2' : null
	},
}

export default {about_us, quote, why, tipe}
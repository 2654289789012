import React, { useEffect, useState } from 'react';
import Base from '../../../utils/base';
import { useLocation } from 'react-router-dom'

export default function ImageDetail(props) {
	var base = new Base()

	return (
    <div className="modal fade" id="image_detail_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <img src={props.image} style={{ width: '100%' }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	)
}
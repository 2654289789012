import { useState, useEffect, } from 'react';
import Base from '../../utils/base';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import prev from '../../assets/about-us/prev@2x.png'
import next from '../../assets/about-us/next@2x.png'

import { Autoplay, Navigation, Pagination } from "swiper";

import '../../pages/about_us/styles.css'

import * as Const from './const.js'
import { data } from 'jquery';

function Slider(props) {
	return (
		<>

			<Swiper
				slidesPerView={3}
				spaceBetween={30}
				autoplay={{
					delay: 2000,
					disableOnInteraction: false
				}}
				navigation={{
					enabled: true,
					prevEl: '#prev',
					nextEl: '#next'
				}}
				loop={true}
				// breakpoints={{
				// 	320: {
				// 		slidesPerView: 3,
				// 	},
				// 	640: {
				// 		slidesPerView: 4,
				// 	},
				// 	960: {
				// 		slidesPerView: 8,
				// 	},
				// }}
				pagination={{
					clickable: true,
				}}
				modules={[Autoplay, Pagination, Navigation]}
				className="mySwiper2 mx-5"
			>
				{
					props.arr.map((data, index) => (
						<SwiperSlide className='d-flex align-items-center justify-content-center'>
							<img src={data.image} alt="" className='img-fluid' />
						</SwiperSlide>
					))
				}
				{/* <div id='prev'> */}
				{/* <img id='prev' src={prev} alt="" srcset=""/> */}
				{/* </div> */}
				{/* <div id="next"> */}
				{/* <img id='next' src={next} alt="" srcset=""/> */}
				{/* </div> */}
			</Swiper>
		</>
	)
}

function ProjectSlider() {
	const loops = [1, 2]
	return (
		<Swiper
			slidesPerView={3}
			spaceBetween={30}
			autoplay={{
				delay: 2000,
				disableOnInteraction: false
			}}
			loop={true}
			// breakpoints={{
			// 	320: {
			// 		slidesPerView: 3,
			// 	},
			// 	640: {
			// 		slidesPerView: 4,
			// 	},
			// 	960: {
			// 		slidesPerView: 8,
			// 	},
			// }}
			pagination={{
				dynamicBullets: true
			}}
			modules={[Autoplay, Pagination]}
			className="mySwiper"
		>
			{/* {
				Const.slider_img.map((data, index) => (
					<SwiperSlide className='d-flex align-items-center justify-content-center'>
						<img src={data} alt="" className='img-fluid' />
					</SwiperSlide>
				))
			} */}
			{loops.map(() => (
				<>
					<SwiperSlide>
						<div className="py-3">
							<a href="http://www.gozco.com" target='_blank'>
								<div className='d-flex flex-column align-items-center scale'>
									<img src={Const.gozco_office} alt="" className='project_img' />
									{/* <img src={Const.fortune_logo} alt="" className='project_logo' /> */}
									<h4 className=' MsnE-Bk pt-3 text-center text-white'>Gozco<br />Capital Office</h4>
								</div>
							</a>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="py-3">
							<a href="http://fortuneindustrialpark.com" target='_blank'>
								<div className='d-flex flex-column align-items-center scale'>
									<img src={Const.fortune_image} alt="" className='project_img' />
									<img src={Const.fortune_logo} alt="" className='project_logo' />
								</div>
							</a>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="py-3">
							<a href="http://juandaland.com" target='_blank'>
								<div className='d-flex flex-column align-items-center scale'>
									<img src={Const.juandaland} alt="" className='project_img' />
									<img src={Const.juandaland_logo_white} alt="" className='project_logo mt-3' />
								</div>
							</a>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="py-3">
							<a href="http://thejuanda.com" target='_blank'>
								<div className='d-flex flex-column align-items-center scale'>
									<img src={Const.juanda_new} alt="" className='project_img' />
									<img src={Const.juanda_logo} alt="" className='project_logo' />
								</div>
							</a>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="py-3">
							<a href="http://thecemandi.com" target='_blank'>
								<div className='d-flex flex-column align-items-center scale'>
									<img src={Const.cemandi_image} alt="" className='project_img' />
									<img src={Const.cemandi_logo} alt="" className='project_logo' />
								</div>
							</a>
						</div>
					</SwiperSlide>
				</>
			))}
		</Swiper>
	)
}

export default function AboutUs() {
	var base = new Base()

	const [test] = useState('123')
	const [arrImage, setArrImage] = useState([])

  useEffect(() => {
    get_data_tenant()
  }, [])

  async function get_data_tenant(){
    var response = await base.request("/about-us/all?type=list_tenant")

    if(response.status === "success"){
      var arr = []
      for(let data of response.data){
        data.image = base.url_image + "/about-us?file_name=" + data.file_name
        arr.push(data)
      }
      setArrImage(arr)
    }
  }

	return (
		<>
			{/* <section id="hero" className='d-flex about_us'>
				<div className="container align-self-center">
					<div className="row mx-5 d-flex flex-column">
						<h1 className=''>About us</h1>
						<div className="d-flex flex-column flex-sm-row">
							<img className='d-none d-sm-block' src={Const.gozco_logo} alt='' />
							<h2 className='ml-sm-4 h1 MsnE-EBd text-white'>Gozco Land<br />Developing<br />for the Future</h2>
						</div>
					</div>
				</div>
			</section> */}
			<section className='about_us' style={{ marginTop: '100px' }}>
				<div className="block">
					<div className="container">
						<div className="about-us">
							<h3 className="heading-title clr-yl">About Us</h3>
							<p className='text-white p-0 text-justify' style={{ lineHeight: '2' }} dangerouslySetInnerHTML={{ __html: Const.about_us }}></p>
						</div>
					</div>
				</div>
			</section>

			{/* <section className='mb-5'>
				<div className="container p-5 border border-white">
					<div className="row">
						<div className="col-12 col-md-auto d-flex flex-column justify-content-start">
							<h3 className="MsnE-Dm clr-yl mb-3">A Development by</h3>
							<img src={Const.surya_logo} alt="" style={{ maxWidth: '260px', width: '100%', height: 'auto' }} />
							<p className='text-white p-0 '></p>
							<a href="http://simtbk.co.id" className='text-white mt-3'>www.simtbk.co.id</a>
						</div>
						<div className="col-12 col-md mt-4 mt-md-0 ml-md-4">
							<p className='text-white p-0 text-justify' style={{ lineHeight: '2' }}><b>RIWAYAT SINGKAT PERUSAHAAN</b></p>
							<p className='text-white p-0 text-justify' style={{ lineHeight: '2' }} dangerouslySetInnerHTML={{ __html: Const.history }}></p>
						</div>
					</div>
				</div>
			</section> */}

			{/* <section className='about_us'>
				<div className="container bg-d-blue pt-5">
					<div className="row p-5">
						<div className="col px-5 pb-5 d-flex align-items-center justify-content-center">
							<img className='img-fluid' src={Const.gozco_logo} alt='' />
						</div>
						<div className="col-sm-12 col-md d-flex flex-column">
							<h3 id='introduce' className='MsnE-Dm'>Introduce about<br />Gozco Land</h3>
							<p className='mb-4'>{Const.gozco_land}</p>
							<h4 className='MsnE-Dm text-white'>Our Vision</h4>
							<p className='mb-4'>{Const.vision}</p>
							<h4 className='MsnE-Dm text-white'>Our Mission</h4>
							<p className='mb-4'>{Const.mission}</p>
						</div>
					</div>
				</div>
			</section> */}

			<section className='about_us mt-5' >
				<div className="container bg-d-blue" style={{minHeight: 'unset'}}>
					<div className="row p-5 my-0">
						<div className="col">
							<h3 className="heading-title clr-yl text-center">Our Project Development</h3>
							<ProjectSlider />
						</div>
					</div>
				</div>
			</section>

			<section className='about_us'>
				<div className="container" style={{minHeight: 'unset'}}>
					<div className="row">
						<div className="col my-5 pt-5 p-0">
							<iframe width="100%" src="https://www.youtube.com/embed/x5DA5SYHLeU" title="Gozco Group Company Profile" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ aspectRatio: '16/9' }}></iframe>
						</div>
					</div>
				</div>
			</section>

			<section className='about_us'>
				<div className="container pb-5" style={{minHeight: 'unset'}}>
					<div className="row p-5">
						<div className="col">
							<h3 className="heading-title clr-yl text-center" style={{textTransform: 'none'}}>Trusted by Tenant</h3>

							<Slider arr={arrImage}/>
							<img id='prev' src={prev} alt="" srcset="" style={{ width: '5rem', position: 'absolute', top: '50%', left: '-2rem', transform: 'translateY(-25%) scale(50%)', cursor: 'pointer' }} />
							<img id='next' src={next} alt="" srcset="" style={{ width: '5rem', position: 'absolute', top: '50%', right: '-2rem', transform: 'translateY(-25%) scale(50%)', cursor: 'pointer' }} />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
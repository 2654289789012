import React from "react";
import Base from "../../../utils/base";

import footerImage from '../../../assets/footer.png'
import instagram from '../../../assets/instagram.png'

import scmd_wa from '../../../assets/socmed-button/whatsapp.png'
import scmd_ig from '../../../assets/socmed-button/instagram.png'
import scmd_yt from '../../../assets/socmed-button/youtube.png'

export default function Footer() {
  return (
    <>
      <div style={{ position: 'fixed', right: '1rem', bottom: '1rem', zIndex: '1000', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {/*<a href='https:\\wa.me\6281337852020?text=' target='_blank'>
          <img src={scmd_wa} style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
        </a>*/}
        <a href='https://fortuneindustrialpark.com/format-pesan/' target='_blank'>
          <img src={scmd_wa} style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
        </a>
        <a href='https:\\www.instagram.com\gedanganindustrialpark\' target='_blank'>
          <img src={scmd_ig} style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
        </a>
        <a href='https:\\www.youtube.com\@gedanganindustrialpark' target='_blank'>
          <img src={scmd_yt} style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
        </a>
      </div>

      <footer className="no-fix" style={{ backgroundColor: 'var(--d-blue)', height: 'unset !important' }}>
        {/* <div className="footer-style2" style={{ backgroundColor: '#152C5D' }}>
          <div className="container">
            <div className="footer-text d-flex flex-column align-items-center">
              <h5 style={{ fontFamily: 'maison-neue-ext-extra-bold', fontSize: '2rem', }}>Marketing Office</h5>
              <h2 style={{ fontFamily: 'maison-neue-ext-extra-bold', fontSize: '2rem', cursor: 'pointer', }} onClick={() => window.open('https://wa.me/+6281337852020', '_blank')}>0813 3785 2020</h2>
              <span style={{ fontFamily: 'maison-neue-ext-book', cursor: 'pointer', }} onClick={() => window.open('http://www.gedanganindustrialpark.com', '_blank')}>www.gedanganindustrialpark.com</span>
              <div className='d-flex align-items-center'>
                <img src={instagram} style={{ width: '2rem' }} />
                <span className='mb-0 ml-3' style={{ fontFamily: 'maison-neue-ext-book', cursor: 'pointer', }} onClick={() => window.open('https://www.instagram.com/gedanganindustrialpark', '_blank')}>gedanganindustrialpark</span>
              </div>
              <img src={footerImage} className='mt-5' style={{ width: '20rem' }} />
            </div>
          </div>
        </div>
        <div className="bottom-strip" style={{ backgroundColor: '#152C5D' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-12">
                <div className="footer-logo">
                  <a href="#" title=""><img src="images/logo.png" alt="" /></a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="copyright-text white text-center">
                  <p>© 2023 QuantumTri. All Right Reserved</p>
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <div className="social-links">
                  <ul className='pl-0'>
                    <li><a href="#" title=""><i className="ion-social-facebook"></i></a></li>
                    <li><a href="#" title=""><i className="ion-social-twitter"></i></a></li>
                    <li><a href="#" title=""><i className="ion-social-googleplus"></i></a></li>
                    <li><a href="#" title=""><i className="ion-social-rss"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container px-lg-0 pt-5 pb-3">
          <div className="row">
            <div className="col-lg-4 mb-4 d-flex flex-column align-items-lg-start">
              <h5 className="MsnE-EBd text-white" style={{ fontSize: '17.5px' }}>Follow Us</h5>
              <p style={{ lineHeight: '1.2' }}>
                <a href="https://www.instagram.com/gedanganindustrialpark/" target="_blank">
                  <span className="fa fa-instagram"></span> @gedanganindustrialpark
                </a><br />
                <a href="https://www.youtube.com/@gedanganindustrialpark/" target="_blank">
                  <span className="fa fa-youtube-play"></span> @gedanganindustrialpark
                </a>
              </p>
            </div>
            <div className="col-lg-4 mb-4 d-flex flex-column align-items-lg-center">
              <h5 className="MsnE-EBd text-white text-lg-center" style={{ fontSize: '17.5px' }}>Marketing Gallery Gedangan Industrial Park</h5>
              <p className="text-lg-center" style={{ lineHeight: '1.2' }}>
                Jl. Raya Bandara Juanda <br />
                Ruko Superblok Permata Juanda Blok A1 <br />
                Sidoarjo - Jawa Timur 61253<br />
                <a href="https://wa.me/6281337852020" target="_blank">
                  <span className="fa fa-whatsapp"></span> 0813 3785 2020
                </a>
              </p>
            </div>
            <div className="col-lg-4 mb-4 d-flex flex-column align-items-lg-end">
              <h5 className="MsnE-EBd text-white text-lg-right" style={{ fontSize: '17.5px' }}>Head Office</h5>
              <p className="text-lg-right" style={{ lineHeight: '1.2' }}>
                Jl. Raya Darmo 54-56, Surabaya<br />
                <span className="fa fa-phone"></span> 031-561 2818 (ext: 317/316)
              </p>
            </div>
          </div>
          <div className="divd-yellow" style={{height: '3px'}}></div>
          <div className="p-3">
            <p className="text-center" style={{fontSize: '80%'}}>DISCLAIMER - Seluruh informasi yang tercantum dalam presentasi ini merupakan alat bantu pemanasan dan bukan merupakan bagian dari perjanjian jual beli.<br/>
            Oleh Karena itu dapat berubah sewaktu-waktu dan merupakan hak penuh pengembang.<br/><br/>
            © 2024 Gedangan Industrial Park</p>
          </div>
        </div>
      </footer >
    </>
  )
}
import { useEffect, useState } from 'react';
import Base from '../../utils/base';

export default function WorkIndex() {
    var base = new Base()

    const [test] = useState('123')

    // useEffect(()=>{
    //     test
    // }, [])

    return (
        <>
            <section>
                <div className="container">
                    <div className="pager-details full text-center">
                        <h2 className="heading-title">our works</h2>
                        <ul>
                            <li><a href="/" title="">Home</a></li>
                            <li><span>Works</span></li>
                        </ul>
                    </div>
                </div>
            </section>


            <section>
                <div className="portfolio-sec">
                    <div className="container">
                        <div className="portfolio-items-filter">
                            <section className="options mgbtm-35">
                                <div className="option-isotop">
                                    <ul id="filter" className="option-set filters-nav" data-option-key="filter">
                                        <li><a className="selected" data-option-value="*">All</a></li>
                                        <li><a data-option-value=".residentials">Residentials</a></li>
                                        <li><a data-option-value=".commercials">commercials</a></li>
                                        <li><a data-option-value=".buildings">Buildings</a></li>
                                        <li><a data-option-value=".industrials">Industrials</a></li>
                                    </ul>
                                </div>
                            </section>
                            <div className="portfolio-grid">
                                <div className="row">
                                    <div className="masonary style2">
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 residentials">
                                            <div className="portfolio-item">
                                                <div className="pt-img">
                                                    <img src="http://via.placeholder.com/370x520" alt="" />
                                                </div>
                                                <div className="item-info">
                                                    <span>Residentials</span>
                                                    <h3><a href="#" title="">UMI Complex Private Residence</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-6 col-xs-12 buildings">
                                            <div className="portfolio-item">
                                                <div className="pt-img">
                                                    <img src="http://via.placeholder.com/770x520" alt="" />
                                                </div>
                                                <div className="item-info">
                                                    <span>Buildings</span>
                                                    <h3><a href="#" title="">UMI Complex Private Residence</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 buildings">
                                            <div className="portfolio-item">
                                                <div className="pt-img">
                                                    <img src="http://via.placeholder.com/1170x520" alt="" />
                                                </div>
                                                <div className="item-info">
                                                    <span>Buildings</span>
                                                    <h3><a href="#" title="">MME Consulting Office</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-6 col-xs-12 commercials">
                                            <div className="portfolio-item">
                                                <div className="pt-img">
                                                    <img src="http://via.placeholder.com/770x520" alt="" />
                                                </div>
                                                <div className="item-info">
                                                    <span>Commercials</span>
                                                    <h3><a href="#" title="">Heydar Aliyev Center in Baku, Azerbaijan</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 buildings">
                                            <div className="portfolio-item">
                                                <div className="pt-img">
                                                    <img src="http://via.placeholder.com/370x520" alt="" />
                                                </div>
                                                <div className="item-info">
                                                    <span>Buildings</span>
                                                    <h3><a href="#" title="">Up-Co Working Office Building</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 buildings">
                                            <div className="portfolio-item">
                                                <div className="pt-img">
                                                    <img src="http://via.placeholder.com/370x520" alt="" />
                                                </div>
                                                <div className="item-info">
                                                    <span>Buildings</span>
                                                    <h3><a href="#" title="">Art & Culture Museum In Barcelona</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 industrials">
                                            <div className="portfolio-item">
                                                <div className="pt-img">
                                                    <img src="http://via.placeholder.com/370x520" alt="" />
                                                </div>
                                                <div className="item-info">
                                                    <span>Industrials</span>
                                                    <h3><a href="#" title="">Beijing Nation Stadium In Beijing, China</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 residentials">
                                            <div className="portfolio-item">
                                                <div className="pt-img">
                                                    <img src="http://via.placeholder.com/370x520" alt="" />
                                                </div>
                                                <div className="item-info">
                                                    <span>Residentials</span>
                                                    <h3><a href="#" title="">Alex’s Villa</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="load-more-items">
                                        <a href="#" title="">Load more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
import { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { Autoplay, Navigation, Pagination, } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player'

import { CSSTransition } from 'react-transition-group'

import ImageDetail from '../layout/modal/image_detail';
import HomeImage from './home_image';

import homeBG from '../../assets/gip-2-bg.png'
import home1 from '../../assets/home1.png'
import home2 from '../../assets/home2.png'
import featuresIcon1 from '../../assets/features1.svg'
import featuresIcon2 from '../../assets/features2.svg'
import featuresIcon3 from '../../assets/features3.svg'
import featuresIcon4 from '../../assets/features4.svg'
import featuresIcon5 from '../../assets/features5.svg'
import featuresIcon6 from '../../assets/asset_icon_kapasitas_beban_lantai.png'
import homeLayout1 from '../../assets/GIP_PK-15.png'
import homeLayout2 from '../../assets/GIP_PK-16.png'
import interior1 from '../../assets/interior1.png'
import interior2 from '../../assets/interior2.png'
import homeLayout3 from '../../assets/GIP_4.png'
import homeLayout4 from '../../assets/industry_logistic.png'
import homeLayout5 from '../../assets/GIP_PK-14_1.png'
import kantinFoodCourt from '../../assets/kantin_foodcourt.png'
import musholla from '../../assets/musholla.png'
import retailArea from '../../assets/retail_area.png'
import rth from '../../assets/RTH.png'
import oneGate from '../../assets/one_gate.png'
import launch_image1 from '../../assets/Group_10.png'
import launch_image2 from '../../assets/Group_11.png'
import launch_image3 from '../../assets/Group_12.png'
import icon_gedangan from '../../assets/icon_gedangan.png'
import fasad1 from '../../assets/01_fasad_render.png'
import kawasan1 from '../../assets/02_Kawasan_1.png'
import fasad from '../../assets/03_Fasad_Real.png'
import kawasan2 from '../../assets/04_Kawasan_2.png'
import interior3 from '../../assets/05_Interior_1.png'
import interior4 from '../../assets/06_Interior_2.png'
import slide_1_asset from '../../assets/slide_1_asset.png'
import slide_2_asset from '../../assets/slide_2_asset.png'
import slide_3_asset from '../../assets/slide_3_asset.png'
import slide_4_asset_tulisan from '../../assets/slide_4_asset_tulisan.png'
import slide_4_asset from '../../assets/slide_4_asset.png'
import gip_intro from '../../assets/video/GIP_Intro.mp4'

export default function HomeIndex() {
	var base = new Base()

	const [test] = useState('123')
	const [arrFacility, setArrFacility] = useState([
		{
			id: '1',
			image: kantinFoodCourt,
			name: 'Kantin/Food Court',
			arr: [
				{
					id: "1",
					name: "Internet Connection (Biznet)",
				},
				{
					id: "2",
					name: "Keamanan 24 Jam & CCTV",
				},
			],
		},
		{
			id: '1',
			image: rth,
			name: 'Ruang Terbuka Hijau',
			arr: [
				{
					id: "1",
					name: "Bebas Kuli Angkut",
				},
				{
					id: "2",
					name: "Air Bersih PDAM",
				},
			],
		},
		{
			id: '1',
			image: oneGate,
			name: 'One Gate System',
			arr: [
				{
					id: "1",
					name: "Musholla",
				},
				{
					id: "2",
					name: "Commercial Shop",
				},
			],
		},
	])
	const [arrFeature, setArrFeature] = useState([
		{
			id: "1",
			image: featuresIcon5,
			title: "Legalitas Jelas<br/>(SHGB murni)",
			body: "",
		},
		{
			id: "1",
			image: featuresIcon1,
			title: "Berlokasi di Kawasan Pergudangan & Industri",
			// body: "Berada di kawasan industri dan dapat untuk produksi.",
			body: "",
		},
		{
			id: "1",
			image: featuresIcon4,
			title: "Bebas Banjir",
			body: "",
		},
		{
			id: "1",
			image: featuresIcon6,
			title: "Kapasitas Beban Lantai 3 ton/m<sup>2</sup>",
			body: "",
		},
		{
			id: "1",
			image: featuresIcon3,
			title: "Loading 24 Jam Bebas Punglli",
			body: "",
		},
		{
			id: "1",
			image: featuresIcon2,
			title: "Professional Estate Management",
			body: "",
		},
	])
	const [arrFront, setArrFront] = useState([
		{
			id: "1",
			image: icon_gedangan,
			title: `Terdepan<br>di <div class='d-inline-block' style="color: #F9AF33">Lokasi</div>`,
			body: "Strategis di Aloha Waru yang menjadi episentrum perdagangan dan perindustrian dengan akses mudah ke berbagai tujuan",
		},
		{
			id: "1",
			image: icon_gedangan,
			title: `Terjamin<br>di <div class='d-inline-block' style="color: #F9AF33">Legalitas</div>`,
			body: "Legalitas Tanah dan PBG resmi, jelas & terpercaya",
		},
		{
			id: "1",
			image: icon_gedangan,
			title: `Terbaik<br>di <div class='d-inline-block' style="color: #F9AF33">Spesifikasi</div>`,
			body: "Kualitas infrastruktur dan spesifikasi teknis terbaik untuk mendukung kemajuan bisnis Anda",
		},
	])
	const [arrSlider, setArrSlider] = useState([
		{
			id: "1",
			image: slide_1_asset,
			image_side: null,
			titleShow: false,
			subtitle1Show: false,
			subtitle2Show: false,
			title: `LOKASI TERDEPAN`,
			subtitle1: `Di Aloha - Waru yang menjadi pusat bisnis & industri`,
			arr_subtitle2: [
				{
					text: `Akses mudah 24 jam ke berbagai tujuan bisnis Anda`,
				},
				{
					text: `yaitu pusat kota Surabaya, Bandara Juanda/cargo,`,
				},
				{
					text: `akses tol dan juga pelabuhan Tanjung Perak`,
				},
			],
		},
		{
			id: "1",
			image: slide_2_asset,
			image_side: null,
			titleShow: false,
			subtitle1Show: false,
			subtitle2Show: false,
			title: `AKSES MUDAH`,
			subtitle1: `Efisiensi Tinggi`,
			arr_subtitle2: [
				{
					text: `Dengan mudahnya akses menuju pergudangan, dapat`,
				},
				{
					text: `meningkatkan penghematan waktu dan juga biaya yang`,
				},
				{
					text: `dikeluarkan.`,
				},
			],
		},
		{
			id: "1",
			image: slide_3_asset,
			image_side: null,
			titleShow: false,
			subtitle1Show: false,
			subtitle2Show: false,
			title: `MIXED USE <br/>& WELL INTEGRATED <br/>DEVELOPMENT`,
			subtitle1: `Gudang + Kantor`,
			arr_subtitle2: [
				{
					text: `Dilengkapi kantor yang terintegrasi langsung dengan`,
				},
				{
					text: `gudang untuk mempermudah pengembangan bisnis`,
				},
			],
		},
		{
			id: "1",
			image: slide_4_asset,
			image_side: slide_4_asset_tulisan,
			titleShow: false,
			subtitle1Show: false,
			subtitle2Show: false,
			title: `SMART<br/>FUNCTION`,
			subtitle1: `Untuk Memaksimalkan Bisnis Anda`,
			arr_subtitle2: [
				{
					text: `Gedangan Industrial Park memiliki infrastruktur dan`,
				},
				{
					text: `fasilitas yang sesuai sebagai sarana bisnis & perindustrian`,
				},
				{
					text: `modern hingga masa yang akan datang`,
				},
			],
		},
	])
	const [selectedImage, setSelectedImage] = useState(null)
	const [mainHeight, setMainHeight] = useState(0)
	const [titleShow, setTitleShow] = useState(false)
	const [subtitle1Show, setSubtitle1Show] = useState(false)
	const [subtitle2Show, setSubtitle2Show] = useState(false)
	const [arrImage, setArrImage] = useState([])
	const [bannerLeft1, setBannerLeft1] = useState('')
	const [bannerRight1, setBannerRight1] = useState('')
	const [bannerLeft2, setBannerLeft2] = useState('')
	const [bannerRight2, setBannerRight2] = useState('')
	const [popupPromo, setPopupPromo] = useState('')

	useEffect(() => {
		console.log(window.innerHeight)
		setMainHeight(window.innerHeight)

		var arr = JSON.parse(JSON.stringify(arrSlider))
		arr[0].titleShow = true
		arr[0].subtitle1Show = true
		arr[0].subtitle2Show = true
		setArrSlider(arr)

		get_data_banner()
		get_data_popup_promo()

		setTimeout(() => {
		}, 3000)
	}, [])

	useEffect(() => {
		setTitleShow(true)
		setSubtitle1Show(true)
		setSubtitle2Show(true)
	}, [arrSlider])

	function onImageClicked(image) {
		setSelectedImage(image)
		window.$('#image_detail_modal').modal('show')
	}

	function onSlideChange(swiper) {
		setTitleShow(false)
		setSubtitle1Show(false)
		setSubtitle2Show(false)
		setTimeout(() => {
			var arr = JSON.parse(JSON.stringify(arrSlider))

			for (let slider of arr) {
				slider.titleShow = false
				slider.subtitle1Show = false
				slider.subtitle2Show = false
			}

			arr[swiper.realIndex].titleShow = true
			arr[swiper.realIndex].subtitle1Show = true
			arr[swiper.realIndex].subtitle2Show = true
			setArrSlider(arr)
		}, 200)
	}

	async function get_data_banner() {
		var response = await base.request("/banner/all")

		if (response.status === "success") {
			for (let x in response) {
				if (x != 'status') {
					var url = base.url_image + "/banner?file_name=" + response[x].file_name
					if (response[x].type == "left1")
						setBannerLeft1(url)
					else if (response[x].type == "right1")
						setBannerRight1(url)
					else if (response[x].type == "left2")
						setBannerLeft2(url)
					else if (response[x].type == "right2")
						setBannerRight2(url)
				}
			}
		}
	}

	async function get_data_popup_promo() {
		var response = await base.request("/popup-promo")

		if (response.status === "success") {
			setPopupPromo(base.url_image + "/popup-promo?file_name=" + response.data.file_name)
			window.$('#popupPromoModal').modal('show')
		}
	}

	function onPlayClicked(){

	}

	return (
		<>
			<ImageDetail image={selectedImage} />
			<div className="modal fade" id="popupPromoModal" style={{ zIndex: 100000, }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className='position-relative'>
							<button type="button" className="close position-absolute" style={{ right: '1rem', top: '1rem', fontSize: '3rem', }} data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
							<img src={popupPromo} style={{ width: '100%', }} />
						</div>
						{/* <div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel"></h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<img src={popupPromo} style={{ width: '100%', }}/>
						</div> */}
					</div>
				</div>
			</div>

			<section style={{}}>

				<div className="block" style={{ height: mainHeight > 0 ? mainHeight + 'px' : 'auto', }}>

					<div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: -1, }}>
						 {/*<iframe src="https://www.youtube.com/watch?v=KWIIPKbdxD0" height="100%" width="100%" style={{ border: 'none', overflow: 'hidden', pointerEvents: 'none' }} scrolling="no"></iframe> */}
						{/*<ReactPlayer
						  playsInline={true}
						  controls={false}
						  playing={true}
						  muted={true}
						  loop={true}
						  className='video_gip_intro'
						  width='100%'
						  height='100%'
						  url={"https://www.youtube.com/watch?v=KWIIPKbdxD0"}
						  type='video/mp4'
						/>*/}
					{/*<video width="300px" height="500px" autoPlay="autoplay" muted loop>
					       <source src="https://user-images.githubusercontent.com/27606753/144578902-9b14dc56-9056-4650-942f-4d25f0ecbcc1.mp4" type="video/mp4" />
					 </video>*/}
						<video className='video_gip_intro' muted loop playsInline autoPlay width="100%" height="100%">
							<source src={gip_intro} type="video/mp4" />
						</video>
						<div className="video_gip_intro_overlay"></div>
					</div>

					<div className="container">
						<div className="kons-construct">
							{/* <h4 style={{ fontFamily: 'maison-neue-book', }}>Kawasan Industri 60 Ha Selatan Surabaya.<br />Pergudangan modern yang terdepan di gedangan.<br />Legalitas JELAS!</h4> */}
							<h4 style={{ fontFamily: 'maison-neue-book', }}>Pergudangan Modern yang terdepan di Selatan Surabaya</h4>
							<h2 style={{ fontFamily: 'maison-neue-ext-demi', }}>THE NEWEST INTEGRATED <span style={{ color: '#F9AF33' }}>INDUSTRIAL PARK</span> AT SOUTH SURABAYA</h2>
						</div>
					</div>

				</div>
			</section>

			<section>
				<div className="block">
					<div className='container'>
						<div className="our-services">
							{/* <div className="kn-title">
								<h3>Our Speciality <br />Include</h3>
							</div> */}
							<div className="srvcs">
								<div className="row main-feature" style={{}}>
									{
										arrFront.map((front, index) => (
											<div className={"col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-5"} key={index}>
												<div className="service-details h-100 m-0" style={{ backgroundColor: '#152C5D', border: '1px solid #152C5D' }}>
													<div className="service-info">
														<div className="srvc-icon d-flex flex-column">
															<img src={front.image} style={{ width: '2rem' }} />
															<h3 className='mb-0 p-0 mt-3' style={{ textTransform: 'unset', fontFamily: 'maison-neue-ext-demi', fontSize: '1.5rem', }} dangerouslySetInnerHTML={{ __html: front.title }}></h3>
														</div>
														<p className='text-white'>{front.body}</p>
													</div>
												</div>
											</div>
										))
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section>
				<div className="block no-padding">
					<div className="konst-main-sec">
						<div className="container">
							<div className="main-construct-sec" style={{ backgroundColor: '#152C5D', }}>
								<div className="intro-kons" style={{ position: 'relative', }}>
									<div className='custom-home-info1' style={{ position: 'absolute', zIndex: 1, }}>
										<img src={bannerRight1} />
										{/* <p className='mb-0' style={{ fontSize: '3rem', color: '#20C4F4', fontFamily: 'maison-neue-bold',  }}>DISTRICT</p>
										<p className='mb-0' style={{ fontSize: '8rem', color: '#20C4F4', fontFamily: 'maison-neue-bold', }}>TETRA<span style={{ fontSize: '2.9rem', }}>(BLOK BH &amp; BI)</span></p> */}
									</div>


									{/* <div className='custom-home-info2' style={{ bottom: 0, position: 'absolute', zIndex: 1, }}>
										<img src={launch_image3}/>
									</div> */}
									{/* <div className='card custom-home-info2' style={{ backgroundColor: '#20C4F4', bottom: 0, position: 'absolute', borderRadius: '1rem', zIndex: 1, transform: 'rotate(-10deg)', boxShadow: '0 .5rem 1rem rgba(0,0,0)' }}>
										<div className='card-body d-flex flex-column align-items-center justify-content-center'>
											<p className='m-0 d-inline-block content1' style={{ lineHeight: 1, fontSize: '1.7rem', color: '#243A76',  }}>Launch</p>
											<p className='m-0 d-inline-block content2' style={{ lineHeight: 1, fontSize: '3rem', color: '#243A76',  }}>NOW</p>
										</div>
									</div> */}

									<div className="row">
										<div className="col-lg-3 p-0">
											<div className='row'>
												<div className='col-4 col-md-12 p-0'>
													<img src={bannerLeft1} style={{ width: '10rem', }} />
													{/* <div className='rounded-circle text-center d-flex align-items-center justify-content-center flex-column' style={{ border: '1px solid white', width: '10rem', height: '10rem' }}>
														<div className='mb-3' style={{ border: '1px solid white', height: '1px', width: '2rem', }}></div>
														<p className='m-0 d-inline-block text-white' style={{ lineHeight: 1, fontSize: '1.7rem',  }}>Launch</p>
														<p className='m-0 d-inline-block text-white' style={{ lineHeight: 1, fontSize: '3rem',  }}>NOW</p>
														<div className='mt-3' style={{ border: '1px solid white', height: '1px', width: '2rem', }}></div>
													</div> */}
												</div>
												<div className='col-8 col-md-12 p-0 pr-3'>
													<img src={bannerLeft2} className='mt-5' style={{ width: '100%', maxWidth: '20rem' }} />
													{/* <p className='m-0 text-white mt-5 d-none d-lg-inline-block' style={{ fontSize: '2rem', fontFamily: 'maison-neue-ext-extra-bold' }}>
														NEW<br/>AMERICAN<br/>WAREHOUSE<br/>DESIGN
													</p>
													<p className='m-0 text-white mt-5 d-inline-block d-lg-none' style={{ fontSize: '2rem', fontFamily: 'maison-neue-ext-extra-bold' }}>
														NEW AMERICAN WAREHOUSE DESIGN
													</p> */}
												</div>
											</div>
											{/* <div className="cons-img">
                                                <img src="http://via.placeholder.com/470x635" alt="" />
                                            </div> */}
										</div>
										<div className="col-lg-9 p-0 my-5 my-lg-0">
											<img src={bannerRight2} alt="" style={{ width: '100%', cursor: 'pointer', }} onClick={() => onImageClicked(home1)} />
											{/* <div className="about-kons">
                                                <div className="kn-title">
                                                    <h3>Introduce about<br /> Kons</h3>
                                                </div>
                                                <h4>Founded in Lebanon in 1967, Kons Construction Company (KCC) has grown to
                                                    become one of the Middle East's leading construction contractors.</h4>
                                                <p>We specialise in complex and prestigious construction and infrastructure
                                                    projects. Our portfolio includes some of the region’s most iconic
                                                    landmarks, from super high-rise luxury developments, to five star
                                                    hotels, hospitals and intricately sophisticated smart buildings. </p>
                                                <p>We have exacting HSE and sustainability standards and the health and
                                                    safety of both our employees and the general public is given utmost
                                                    priority.</p>
                                                <a href="#" title="">Read More</a>
                                                <div className="our-projects-counter">
                                                    <ul>
                                                        <li>
                                                            <h2>24</h2>
                                                            <h4>Years <br /> Experience</h4>
                                                        </li>
                                                        <li>
                                                            <h2>865</h2>
                                                            <h4>Projects <br /> Completed</h4>
                                                        </li>
                                                        <li>
                                                            <h2>4200</h2>
                                                            <h4>Workers <br /> Employed</h4>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
										</div>
									</div>


								</div>

								<div style={{ width: '100%', float: 'left', marginTop: '10rem', }}>
									<Swiper
										modules={[Pagination, Autoplay,]}
										spaceBetween={0}
										slidesPerView={1}
										loop={true}
										autoplay={{
											delay: 10000,
											disableOnInteraction: false,
										}}
										pagination={true}
										onSlideChange={(swiper) => onSlideChange(swiper)}
										className="mySwiper">
										{
											arrSlider.map((slider, index) => (
												<SwiperSlide key={index}>
													<div className='d-flex align-items-center justify-content-between swiper-item' style={{}}>
														<div className='swiper-item1'>
															<CSSTransition
																in={titleShow && slider.titleShow}
																timeout={1000}
																appear
																unmountOnExit
																classNames="title">
																<p style={{ fontFamily: 'maison-neue-ext-demi', color: '#F9AF33', fontSize: '3rem', lineHeight: 1, }} dangerouslySetInnerHTML={{ __html: slider.title }}></p>
															</CSSTransition>

															<div style={{ marginTop: '1rem', }}>
																<CSSTransition
																	in={subtitle1Show && slider.subtitle1Show}
																	timeout={1000}
																	appear
																	unmountOnExit
																	classNames="title">
																	<div style={{ color: 'white', fontFamily: "maison-neue-ext-extra-bold" }}>{slider.subtitle1}</div>
																</CSSTransition>
																<CSSTransition
																	in={subtitle2Show && slider.subtitle2Show}
																	timeout={1000}
																	appear
																	unmountOnExit
																	classNames="title">
																	<div>
																		{
																			slider.arr_subtitle2.map((subtitle, index1) => (
																				<div style={{ color: 'white', }} key={index1}>{subtitle.text}</div>
																			))
																		}
																	</div>
																</CSSTransition>
															</div>
														</div>

														<div className='d-flex align-items-center justify-content-end mt-5 mt-md-0'>
															<img src={slider.image} className={slider.image_side != null ? 'side-image' : 'full-image'} />
															{
																slider.image_side != null &&
																<img src={slider.image_side} style={{ width: '20rem', marginLeft: '3rem', maxWidth: '50%' }} />
															}
														</div>
													</div>
												</SwiperSlide>
											))
										}
									</Swiper>
								</div>

								<div className="project-gallery">
									{/* <div className="kn-title pd">
										<h3 style={{ fontFamily: 'maison-neue-ext-demi' }}>Features</h3>
									</div> */}
									<div className="pro-gallery">
										{/* <div className='row'>
											<div className='col-12 col-md-6 pl-0 pr-0 pr-md-5'>
												<img src={homeLayout1} alt="" style={{ width: '100%', cursor: 'pointer', }} onClick={() => onImageClicked(homeLayout1)}/>
											</div>
											<div className='col-12 col-md-6 pr-0 pl-0 pl-md-5 mt-5 mt-md-0'>
												<img src={homeLayout2} alt="" style={{ width: '100%', cursor: 'pointer', }} onClick={() => onImageClicked(homeLayout2)}/>
											</div>
										</div> */}

										<div className="row">
											<div className="col-lg-6 pr-0 pr-md-5 h-100">
												<h3 className='my-5' style={{ color: 'white', fontFamily: 'maison-neue-ext-extra-bold', }}>GROW YOUR INDUSTRY <br />TO <span style={{ color: '#F9AF33' }}>PROGRESS</span></h3>

												<HomeImage image={kawasan1} title="" content="" onClicked={(image) => onImageClicked(image)} />
											</div>
											<div className="col-lg-6 pl-0 pl-md-5 d-flex flex-column">
												<div className="kn-title pd d-inline-block" style={{ width: 'auto', float: 'none', }}>
													<h3 className='my-5' style={{ fontFamily: 'maison-neue-ext-demi', marginLeft: 0, }}>Features</h3>
												</div>
												<div className='h-100'>
													<div className='row h-100'>
														{/* {
															arrFeature.map((feature, index) => (
																<div className={'col-4 p-0 text-center d-flex align-items-start align-items-lg-center '} key={index}>
																	<div className='text-center d-inline-block' style={{ width: '100%' }}>
																		{
																			feature.title !== "" ?
																				<div className='d-flex align-items-center flex-column'>
																					<img src={feature.image} style={{ height: index == 3 ? '3rem' : '5rem', marginTop: index == 3 && '1rem' }} />
																					<p className='mb-0' style={{ color: '#F9AF33', fontSize: '1rem', fontFamily: 'maison-neue-ext-extra-bold', marginTop: index == 5 ? '1rem' : '0' }} dangerouslySetInnerHTML={{ __html: feature.title }}></p>
																					{
																						feature.body !== "" &&
																						<p className='mb-0 mt-1 text-white' style={{ fontSize: '1rem', }} dangerouslySetInnerHTML={{ __html: feature.body }}></p>
																					}
																				</div>
																				:
																				<div className='d-flex align-items-center flex-column'>
																					<img src={feature.image} style={{ width: '100%' }} />
																				</div>
																		}
																	</div>
																</div>
															))
														} */}
														{
															arrFeature.map((feature, index) => (
																<div className={'col-4'} key={index}>
																	<div className='d-flex align-items-center flex-column'>
																		<img src={feature.image} style={{ height: index == 3 ? '3rem' : '5rem', margin: index == 3 && '1rem' }} />
																		<p className='mb-0 text-center' style={{ color: '#F9AF33', fontSize: '1rem', fontFamily: 'maison-neue-ext-extra-bold' }} dangerouslySetInnerHTML={{ __html: feature.title }}></p>
																	</div>
																</div>
															))
														}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='w-100 d-inline-block' style={{ margin: '10rem 0', }}>
										<div className='row w-100'>
											<div className='col-12 col-md-6 gallery-item1'>
												<HomeImage image={fasad} title="" content="" onClicked={(image) => onImageClicked(image)} />
											</div>
											<div className='col-12 col-md-6' style={{ padding: '0', }}>
												<div className='row'>
													<div className='col-12 gallery-item2'>
														<HomeImage image={kawasan2} title="" content="" onClicked={(image) => onImageClicked(image)} />
													</div>
													<div className='col-6 gallery-item3'>
														<HomeImage image={interior3} title="" content="" onClicked={(image) => onImageClicked(image)} />
													</div>
													<div className='col-6 gallery-item4'>
														<HomeImage image={interior4} title="" content="" onClicked={(image) => onImageClicked(image)} />
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* <div className="pro-gallery">
										<div className=''>
											<img src={homeLayout5} style={{ width: '100%', cursor: 'pointer',  }} onClick={() => onImageClicked(homeLayout5)}/>
										</div>
									</div> */}
								</div>


								{/* <div className="project-gallery mb-5 pb-5">
									<div className="kn-title pd">
										<h3 style={{ fontFamily: 'maison-neue-ext-demi' }}>Facility & Infrastructure</h3>
									</div>
									<div className="pro-gallery">
										<div className="row">
											{
												arrFacility.map((facility, index) => (
													<div className={"col-lg-4 " + (index == 0 ? 'pl-0 pr-0 pr-lg-3' : (index == arrFacility.length - 1 ? 'pr-0 pl-0 pl-lg-3' : 'px-0 px-lg-3')) + ' ' + (index > 0 ? 'mt-5 mt-lg-0' : '')} key={index}>
														<div className='card h-100' style={{ border: 'none', borderRadius: '1rem', backgroundColor: '#152C5D', boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)' }}>
															<div className='card-body p-0' style={{  }}>
																<img src={facility.image} alt="" style={{ width: '100%', height: '15rem', objectFit: 'cover', cursor: 'pointer', borderRadius: '1rem 1rem 0 0', }} onClick={() => onImageClicked(facility.image)}/>
																<div className='p-4'>
																	<div className='d-inline-block w-100'>
																		<p className='mb-0 d-inline-block text-white' style={{ fontSize: '2rem', fontFamily: 'maison-neue-ext-extra-bold', }}>{facility.name}</p>
																		<div className='mt-1' style={{ border: '1px solid #FBB034', width: '100%', height: '1px' }}></div>
																	</div>
																	<div className='mt-5'>
																		{
																			facility.arr.map((data, index1) => (
																				<div key={index1} className='mt-2'>
																					<p className='mb-0 text-white' style={{ fontSize: '1.3rem', fontFamily: 'maison-neue-ext-demi', }}>{data.name}</p>
																					<div className='mt-1' style={{ border: '1px solid #FBB034', width: '100%', height: '1px' }}></div>
																				</div>
																			))
																		}
																	</div>
																</div>
															</div>
														</div>
													</div>
												))
											}
											
										</div>
									</div>
								</div> */}


								<div className="col my-5 pt-5 p-0">
									{/*<iframe width="100%" src="https://www.youtube.com/embed/1d4aFi7MsUc?si=8FlIFDIibxDy_4Mr" title="Gozco Group Company Profile" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ aspectRatio: '16/9' }}></iframe>*/}
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
import React, { Component } from 'react'
// import '../App.css'
import axios from 'axios'
import moment from 'moment'
import Jquery from 'jquery'

import 'popper.js'
import 'bootstrap'


export default class Base extends Component {

	host = 'https://gedanganindustrialpark.com/admin'

	base_url = this.host + '/api';
	url_image = this.host + '/image';

	timeoutAxios = 300000
	currencyFormat = 'id-ID'
	app_version = '0.1.0003'

	axios = axios
	moment = moment
	$ = Jquery

	constructor(props) {
		super(props)
		axios.defaults.headers.common['Content-Type'] = 'application/json'
		axios.defaults.headers.common['Accept'] = 'application/json'
		axios.defaults.timeout = this.timeoutAxios
	}

	img_no_image = require('../assets/img/no_image_available.jpeg')
	img_no_profile = require('../assets/img/no_profile_picture.png')

	async request(url, method = "get", data = {}, onUploadProgress = response => { }) {
		var token = await localStorage.getItem('token')
		if (token != null) {
			axios.defaults.headers.common['Authorization'] = token
		}

		try {
			var response
			if (method === 'get') {
				response = await axios.get(this.base_url + url)
			}
			else if (method === 'post') {
				response = await axios.post(this.base_url + url, data, {
					headers: { "Content-Type": "application/json" },
					onUploadProgress
				})
			}
			else if (method === 'put') {
				response = await axios.put(this.base_url + url, data, {
					headers: { "Content-Type": "application/json" },
					onUploadProgress
				})
			}
			else if (method === 'delete') {
				response = await axios.delete(this.base_url + url)
			}

            return response.data

			// if (response.status !== 401) {
			// 	return response.data
			// }
			// else {
			// 	localStorage.clear()
			// 	window.location.href = '/auth/login'
			// }
		} catch (e) {
			setTimeout(() => {
				console.log(this.base_url + url, e)
			}, 500)
		}
	}

	// img_welcome = require('../assets/img/welcome.png')

	async update_array(arr, set_state, data = {}, index = 0) {
		var temp = [...arr]
		temp[index] = data
		set_state(temp)
	}

	update_object(data, set_state, variable = null, key = '') {
		var temp = JSON.parse(JSON.stringify(data))
		temp[key] = variable
		set_state(temp)
	}

	add_array(arr, set_state, data = {}) {
		var temp = [...arr]
		temp.push(data)
		set_state(temp)
	}

	unshift_array(arr, set_state, data = {}) {
		var temp = [...arr]
		temp.unshift(data)
		set_state(temp)
	}

	remove_array(arr, set_state, index = 0) {
		var temp = [...arr]
		temp.splice(index, 1)
		set_state(temp)
	}

	url_photo(type, file_name) {
		var image_url = this.url_image + '/' + type + '?file_name=' + file_name + '&rnd=' + moment().format('HHmmss')
		return image_url
	}

	responsive_scroll_threshold(value, additional_margin = 0){
    if(window.screen.width < 720)
      value = value * (12 / 16) + additional_margin
    else if(window.screen.width >= 720 && window.screen.width < 1024)
      value = value * (8 / 16)
    // console.log(value)
    return value
  }

}
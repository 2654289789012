import React from 'react';
import Base from '../../utils/base';

import 'swiper/css/bundle';
import "swiper/css/pagination";
import "swiper/css/navigation";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeIndex from '../home';
import Navbar from './navbar';
import AboutUs from '../about_us';
import ContactUsIndex from '../contact_us';
import WorkIndex from '../work';
import Unit from '../unit';
import News from '../news';
import gip_intro from '../../assets/video/GIP_Intro.mp4'

import Footer from './footer';
import NewsDetails from '../news/news_detail';

// import Navbar from './navbar'
// import SideBar from './sidebar'

export default class BaseLayout extends Base {
    state = {
        scrollY: -1,
    }

    async componentDidMount() {
        var context = this
        // window.addEventListener('scroll', () => context.handleScroll())
        const last_version = localStorage.getItem('version')
        if(last_version == null || (last_version != null && last_version !== this.app_version)){
            localStorage.setItem('version', this.app_version)
            window.location.reload(true);
        }
    }
    
    async handleScroll(){
        await this.setState({ scrollY: window.scrollY })
		// setScrollY(window.scrollY)
	}

    render() {
        return (
            <>
                <Router>
                    {/* <div className="page-loading">
                        <div className="thecube">
                            <div className="cube c1"></div>
                            <div className="cube c2"></div>
                            <div className="cube c4"></div>
                            <div className="cube c3"></div>
                        </div>
                    </div> */}
                    
                    <div className="wrapper" >

                    <Navbar scrollY={this.state.scrollY}/>

                        <div className='d-flex flex-column h-100' style={{ overflow: 'auto', }}>
                            

                            <div className='' style={{minHeight: 'calc( 100vh - 269px)'}}>
                                <Routes>
                                    <Route exact path={"/"} element={<HomeIndex />}></Route>
                                    
                                    <Route exact path={"/works"} element={<WorkIndex />}></Route>
                                    <Route exact path={"/unit"} element={<Unit />}></Route>
                                    <Route exact path={"/about-us"} element={<AboutUs />}></Route>
                                    <Route exact path={"/news"} element={<News />}></Route>
                                    <Route exact path={"/contact-us"} element={<ContactUsIndex />}></Route>
                                    <Route exact path={"/news-details"} element={<NewsDetails />}></Route>
                                </Routes>
                            </div>

                            <div className='mt-auto'>
                                <Footer />
                            </div>
                        </div>                            
                       
                    </div>

                    
                </Router>
            </>
        );
    }
}
import { useEffect, useState } from 'react';
import Base from '../../utils/base';

export default function ContactUsIndex() {
	var base = new Base()

	const [test] = useState('123')

	// useEffect(()=>{
	//     test
	// }, [])

	return (
		<>
			{/* <section>
                <div className="block no-padding">
                    <div className="main-map">
                        <div id="map" className="map"></div>
                    </div>
                </div>
            </section> */}

			<section>
				<div className="contact-info-sec">
					<div className="container">
						<div className="cntct-details">
							<div className="row">
								<div className="col-lg-6">
									<div className="contact-address">
										<h2><strong>Sidoarjo, </strong>Jawa Timur</h2>
										<p>Jl.Muncul Gedangan, <br />
										Bohar Timur, Bohar, Kec. Taman, <br />
										Kabupaten Sidoarjo, Jawa Timur 61257</p>
										{/* <span><b>Email:</b> infor@kons.com</span> */}
										<a className='btn btn-light' href="https://maps.google.com/?q=-7.3802413,112.7167309&hl=id-ID&gl=id&entry=gps&lucs=47062720&g_st=iw" target="_blank" rel="noopener noreferrer">Location</a>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="contact-more-info">
										<h5>Call directly:</h5>
										<a href="tel:+6281337852020">
											<h2>(+62) 813-3785-2020</h2>
										</a>
										<div className="address">
											<h3>Head Office :</h3>
											{/* <ul>
												<li><a href="#" title="">Allentown PA</a></li>
												<li><a href="#" title="">Allanta, GA</a></li>
												<li><a href="#" title="">Chicago, IL</a></li>
												<li><a href="#" title="">Dallas, TX</a></li>
												<li><a href="#" title="">Edison, NJ</a></li>
												<li><a href="#" title="">Houston, TX</a></li>
											</ul> */}
											<p className="d-inline" style={{ lineHeight: '1.2' }}>
												Gedung Gozco,<br />
												Jl. Raya Darmo 54-56, Tegalsari<br />
												Surabaya - Jawa Timur<br />
												<span className="fa fa-phone d-inline"></span> 031-561 2818 (ext: 317/316)
											</p>
										</div>
										<div className="address">
											<h3>Work hours:</h3>
											<span>Setiap Hari, 8:00 - 17:00 WIB</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3956.764746879539!2d112.71454221477559!3d-7.380241294674671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwMjInNDguOSJTIDExMsKwNDMnMDAuMiJF!5e0!3m2!1sid!2sid!4v1686794992634!5m2!1sid!2sid" style={{border:'none', width: '100%', aspectRatio: '2/1'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
						<div className="contact-form-sec">
							<h3>Have A Question? Contact Us</h3>
							<p className="success alert alert-success" id="success" style={{ display: 'none' }}></p>
							<p className="error alert alert-danger" id="error" style={{ display: 'none' }}></p>
							<form name="contact_form_3" id="contact_form_3" method="post">
								<input type="hidden" name="axn" value="contact_3" />
								<div className="row">
									<div className="col-lg-4">
										<input type="text" name="username" id="username" placeholder="Name*" />
									</div>
									<div className="col-lg-4">
										<input type="text" name="email" id="email" placeholder="Email*" />
									</div>
									<div className="col-lg-4">
										<input type="text" name="subject" placeholder="Subject(Optional)" />
									</div>
									<div className="col-lg-12">
										<textarea name="description" id="description" placeholder="Message"></textarea>
									</div>
									<div className="col-lg-12 text-center">
										<button type="button" id="submit_3">Send Message</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
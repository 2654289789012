import article from './const.js'
import { useEffect, useState } from 'react';
import Base from '../../utils/base.js'

function RelatedArticle(props) {
  return (
    <li className="mb-2">
      <div class="views-field views-field-title">
        <a className='text-white hvr-yl' href={'news-details?id=' + props.data.id}>
          <div>
            {props.data.title}
          </div>
        </a>
      </div>
    </li>
  )
}

function CardArticle(props) {
  return (
    <div className="card mb-4 bg-d-blue border-0">
      <div style={{ aspectRatio: 2 / 1, backgroundImage: 'url(' + props.data.article_image + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', }}></div>
      <div className="card-body">
        <small className='text-light'>{props.data.date}</small>
        <a href={'news-details?id=' + props.data.id}>
          <h3 className="card-title text-white MsnE-Dm" max>{props.data.title}</h3>
          Read More ...
        </a>
      </div>
    </div>
  )
}

export default function News() {
  var base = new Base()
  const [arr, setArr] = useState([])

  useEffect(() => {
    get_data()
  }, [])

  async function get_data(){
    var response = await base.request("/news/all")

    if(response.status === "success"){
      var arr = []
      for(let data of response.data){
        data.article_image = base.url_image + "/news?file_name=" + data.file_name
        arr.push(data)
      }
      setArr(arr)
    }
  }

  return (
    <div className="container" style={{ paddingTop: '104px' }}>
      <div className="row mt-5">
        <div className="col-sm-8">
          {
            arr.map((data, index) => <CardArticle data={data} />)
          }
        </div>

        <aside className="col-sm-4 col-xs-12">
          {/* <div className="p-3 mb-4 bg-d-blue">
            <h4 className="MsnE-Dm clr-yl mb-3">Search</h4>
            <input type="text" className="form-control" name="searchBox" id="searchBox" aria-describedby="helpId" placeholder="Search" />
          </div> */}
          <div className="p-3 mb-4 bg-d-blue">
            <h4 className="MsnE-Dm clr-yl mb-3">Related Article</h4>
            <ul style={{ padding: '0', margin: '0', listStyle: 'none' }}>
              {
                arr.map((data, index) => <RelatedArticle data={data} />)
              }
            </ul>
          </div>
        </aside>

      </div>
    </div>
  )
}
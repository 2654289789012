import { useEffect, useState, useRef, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation, useSearchParams, } from 'react-router-dom';
import cardImage from '../../assets/about_us/001.webp'
import article from './const.js'
import { BrowserRouter } from 'react-router-dom'
import moment from 'moment';

import Base from '../../utils/base.js'

// function RelatedArticle() {
//   return (
//     <>
//       <li className="mb-2">
//         <div class="views-field views-field-title">
//           <a className='text-white' href="/blog/top-10-unique-buildings-shanghai-china" hreflang="en">
//             Top 10 Unique Buildings In Shanghai, China
//           </a>
//         </div>
//       </li>
//     </>
//   )
// }

// function CardArticle(props) {
//   return (
//     <>
//     {/* {
//       console.log(props)
//     } */}
//     <a href={'news-details/'+props.data.id}>
//       <div className="card mb-4 bg-d-blue border-0" onClick={() => {alert("article")}} style={{cursor: 'pointer'}}>
//         <div style={{aspectRatio: 2/1, backgroundImage: 'url('+cardImage+')', backgroundSize: 'cover'}}></div>
//         {/* <img className="card-img-top" src={cardImage} alt="Title" /> */}
//         <div className="card-body">
//           <small className='text-light'>Admin | Articles Date</small>
//           <h3 className="card-title text-white MsnE-Dm">{props.data.title}</h3>
//           <a href='#'>
//               Read More ...
//           </a>
//           <p className="card-text" style={{lineHeight: '1.2'}}>
//           </p>
//         </div>
//       </div>
//     </a>
//     </>
//   )
// }

function useQuery(){
  const {search} = useLocation()
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function NewsDetails() {
  var base = new Base()
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState('')
  const [image, setImage] = useState('')
  const [date, setDate] = useState(moment)
  const [content, setContent] = useState('')

  useEffect(() => {
    get_data()
  }, [])

  async function get_data(){
    var response = await base.request("/news?id=" + searchParams.get("id"))

    if(response.status === "success"){
      setTitle(response.data.title)
      setImage(base.url_image + "/news?file_name=" + response.data.file_name)
      setDate(moment(response.data.created_at.replace('T', ' ').replace('Z', ''), 'YYYY-MM-DD HH:mm:ss'))
      setContent(response.data.content)
    }
  }


  // const _RelatedArticle = []
  // for (let i = 0; i < 4; i++) {
  //   _RelatedArticle.push(<RelatedArticle />)
  // }
  
  // const _CardArticle = []
  // for (let i = 0; i < 4; i++) {
  //   _CardArticle.push(<CardArticle />)
  // }


  return (
    <div style={{ marginTop: '10rem', }}>
      <div style={{ }} className='container d-flex align-items-end justify-content-center'>
        <img src={image}/>
      </div>
      {/* <div style={{backgroundImage: 'url('+image+')', minHeight: '75vh', width: '100%', backgroundSize: 'cover', backgroundPosition: 'center'}}>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-12 py-5">
            <h1 className='MsnE-Dm text-white' style={{maxWidth: '30ch'}}>{title}</h1>
            <small className='text-light'>{date.format('DD MMMM YYYY HH:mm')}</small>
          </div>
          <div className="col-12 mb-5">
            <p className='newsDetailHTMLContent' style={{lineHeight: 1.3, maxWidth: '75ch', fontSize: '1rem'}} dangerouslySetInnerHTML={{__html: content}}></p>
          </div>
        </div>
      </div>
    </div>
  )
}
import { useEffect, useState, useRef, } from 'react';
import { CSSTransition, } from 'react-transition-group';
import Base from '../../utils/base';

import img001 from '../../assets/about_us/001.webp'
import img002 from '../../assets/about_us/002.webp'
import img003 from '../../assets/about_us/003.webp'
import img003_mobile from '../../assets/about_us/003_mobile.webp'
import accent from '../../assets/about_us/accent.svg'

import icon001 from '../../assets/about_us/Icon_001.png'

import tetra from '../../assets/about_us/Tetra.png'
import siteplan from '../../assets/about_us/Siteplan.png'
import back from '../../assets/about_us/back.png'
import tipe01_lt1 from '../../assets/about_us/tipe_01/denah_lt1.png'
import tipe01_lt2 from '../../assets/about_us/tipe_01/denah_lt2.png'

import txt from './const'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import { Button } from 'bootstrap';

function Slider(props) {
	console.log(props.arr[0].id)
	return (
		<Swiper
			slidesPerView={1}
			loop={true}
			autoplay={{
				delay: 3000,
				// disableOnInteraction: false
			}}
			modules={[Autoplay]}
		>
			{
				props.arr.map((data, index) => (
					<SwiperSlide>
						<div className="banner-section d-flex" style={{ width: '100vw', maxHeight: '100vh', aspectRatio: '16/9', backgroundImage: `url(${data.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
							<div className='container d-flex'>
								<div className='d-flex flex-column align-self-center scaleMdLowHalf'>
									<div className='d-inline-flex flex-column align-self-center p-4' style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
										<p className='h5 text-white' style={{ fontFamily: 'maison-neue-ext-book' }}>{data.subtitle}</p>
										<h1 className='display-4 text-uppercase MsnE-Dm clr-l-bl'>{data.title}</h1>
										<p className='h5 MsnE-Bk' style={{ color: 'hsl(0,0%,70%)' }}>{data.subcontent}</p>
										<p className='h5 MsnE-Bk text-white' style={{ maxWidth: '37ch', lineHeight: 1.3 }} dangerouslySetInnerHTML={{__html: data.content}}></p>
									</div>
									<div class="d-grid gap-2 mt-4">
										{/* <a href='#' type="button" className=" d-inline-flex flex-column align-items-center" data-toggle="modal" data-target={modal + props.tipe.id} data-backdrop="false"> */}
										{ data.id == "UNIT_20231009_000001" ?
										<a name="banner-detail" id="banner-detail" class="btn ml-2 MsnE-EBd clr-bl text-uppercase" href="#" role="button" style={{ background: '#FBB034', fontSize: '1.5rem' }} data-toggle="modal" data-target={'#type_1'} data-backdrop="true">View Detail</a>
										:
										<a name="banner-detail" id="banner-detail" class="btn ml-2 MsnE-EBd clr-bl text-uppercase" href="#" role="button" style={{ background: '#FBB034', fontSize: '1.5rem' }}>View Detail</a>
										}
									</div>
								</div>
							</div>
						</div>
					</SwiperSlide>
				))
			}
			{/* <SwiperSlide>
				<div className="banner-section d-flex" style={{ width: '100vw', maxHeight: '100vh', aspectRatio: '16/9', backgroundImage: `url(${img001})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
					<div className='container d-flex'>
						<div className='d-flex flex-column align-self-center scaleMdLowHalf'>
							<div className='d-inline-flex flex-column align-self-center p-4' style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
								<p className='h5 text-white' style={{ fontFamily: 'maison-neue-ext-book' }}>Rekomendasi Unit</p>
								<h1 className='display-4 text-uppercase MsnE-Dm clr-l-bl'>District Tetra</h1>
								<p className='h5 MsnE-Bk' style={{ color: 'hsl(0,0%,70%)' }}>Ready Unit</p>
								<p className='h5 MsnE-Bk text-white' style={{ maxWidth: '37ch', lineHeight: 1.3 }}>Unit Ready District Tetra merupakan Produk Gudang
									unggulan kami yang memiliki Tampilan Fasad Modern
									berkonsep “American Warehouse”</p>
							</div>
							<div class="d-grid gap-2 mt-4">
								<a name="banner-detail" id="banner-detail" class="btn ml-2 MsnE-EBd clr-bl text-uppercase" href="#" role="button" style={{ background: '#FBB034', fontSize: '1.5rem' }}>View Detail</a>
							</div>
						</div>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="banner-section d-flex" style={{ width: '100vw', maxHeight: '100vh', aspectRatio: '16/9', backgroundImage: `url(${img003})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
					<div className='container d-flex'>
						<div className='d-flex flex-column align-self-center scaleMdLowHalf'>
							<div className='d-inline-flex flex-column align-self-center p-4' style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
								<p className='h5 text-white' style={{ fontFamily: 'maison-neue-ext-book' }}>Kawasan Industri</p>
								<h1 className='display-4 text-uppercase MsnE-Dm clr-l-bl'>District Tetra</h1>
								<p className='h5 MsnE-Bk' style={{ color: 'hsl(0,0%,70%)' }}>2,8 Ha</p>
								<p className='h5 MsnE-Bk text-white' style={{ maxWidth: '37ch', lineHeight: 1.3 }}>Kawasan Pengembangan yang terintegrasi Seluas 60 Hektar.</p>
							</div>
							<div class="d-grid gap-2 mt-4">
								<a name="banner-detail" id="banner-detail" class="btn ml-2 MsnE-EBd clr-bl text-uppercase" href="#" role="button" style={{ background: '#FBB034', fontSize: '1.5rem' }}>View Detail</a>
							</div>
						</div>
					</div>
				</div>
			</SwiperSlide> */}
		</Swiper>
	)
}

function Why(props) {
	return (
		<>
			<div className="col-lg-3 col-md-6 col-sm-6 pb-5 pb-lg-0">
				<div className="service-details" style={{height: '100%', margin: 'unset'}}>
					<div className="service-info">
						<div className="d-flex align-items-center">
							{/* <span className="lnr lnr-apartment"></span> */}
							<img src={props.why.icon} width={'48px'} height={'48px'}></img>
							<h3 className='text-white p-0 m-0 pl-3 text-uppercase MsnE-EBd' style={{ fontSize: '17px' }}><span className='clr-yl'>{props.why.title1}</span><br />{props.why.title2}</h3>
						</div>
						<p className='text-white mt-3' style={{ lineHeight: '20px' }}>{props.why.desc}</p>
					</div>
				</div>
			</div>
		</>
	)
}

function Tipe(props) {
	var modal = "#type_"
	return (
		<>
			<div className='col-6 col-lg-3 mt-3 mt-lg-0 d-flex flex-column align-items-center'>
				<a href='#' type="button" className=" d-inline-flex flex-column align-items-center" data-toggle="modal" data-target={modal + props.tipe.id} data-backdrop="false">
					<img src={icon001} style={{ width: '100px', height: '100px' }} />
					<p className='text-white text-center mb-2'>Gudang {props.tipe.warehouse.v} Lantai<br />Kantor {props.tipe.office.v} Lantai</p>
					<p className='px-2 pt-1 rounded h3 text-dark d-inline MsnE-Dm' style={{ backgroundColor: 'var(--l-blue)' }}>{props.tipe.lt.p} x {props.tipe.lt.l}</p>
					<small className='text-white text-center'>LT. {props.tipe.lt.v} m<sup>2</sup> | LB. {props.tipe.lb.v} m<sup>2</sup></small>
				</a>
			</div>
		</>
	)
}

function Tipe_Modal(props) {
	var modal = "type_"
	return (
		<>
			<div className="modal fade" id={modal + props.tipe.id} tabindex="-1" role="dialog" aria-labelledby={modal + props.tipe.id + "Title"} aria-hidden="true" style={{ padding: '0 !important' }}>
				<div className="modal-dialog modal-lg" role="document" style={{ maxWidth: '1170px', margin: 'auto' }}>
					<div className="modal-content rounded-0">
						<div className="modal-header pb-4 pt-5 px-5 justify-content-start border-0">
							<button type="button" className="p-0 m-0 border-0" data-dismiss="modal" aria-label="Close" style={{ background: 'none', cursor: 'pointer' }}>
								<span aria-hidden="true" className='d-flex align-items-center'>
									<img src={back} style={{ width: '16px', height: '16px' }} />
									<h5 className="modal-title pl-2 MsnE-Dm clr-bl ltr-spc-25" id="type_1Title">BACK</h5>
								</span>
							</button>
						</div>
						<div className='px-5'>
							<div className='divd-yellow'></div>
						</div>
						<div className="modal-body p-5">
							<p className='h5 MsnE-Dm clr-bl ltr-spc-25'>Tipe</p>
							<p className='h5 mt-4 text-center MsnE-Dm clr-bl ltr-spc-25'>GUDANG {props.tipe.warehouse.v} LANTAI ({props.tipe.warehouse.p}x{props.tipe.warehouse.l} m) | OFFICE {props.tipe.office.v} LANTAI ({props.tipe.office.p}x{props.tipe.office.l} m)</p>
							<div className='divd-yellow mt-5'></div>
							<p className='h5 mt-5 MsnE-Dm clr-bl ltr-spc-25'>Denah Lantai</p>

							<div className='row pt-5'>
								<div className={'col-12 col-md-6 ' + (props.tipe.floor2 !== null ? 'col-lg-4' : 'col-lg-8') + ' mb-4 d-flex align-items-end justify-content-center'}>
									<img className='mw-100' src={props.tipe.floor1} />
								</div>
								{
									props.tipe.floor2 !== null ?
										<div className='col-12 col-md-6 col-lg-4 mb-4 d-flex align-items-end justify-content-center'>
											<img className='mw-100' src={props.tipe.floor2} />
										</div>
										: ""
								}
								<div className='col-12 col-md-6 col-lg-4 mb-4 d-flex flex-column justify-content-end'>
									<p className='h4 MsnE-Dm clr-bl'>Luas Tanah ({props.tipe.lt.v} m<sup>2</sup>)</p>
									<p className='h4 MsnE-Dm clr-yl mb-3'>{props.tipe.lt.p} x {props.tipe.lt.l} m</p>
									<p className='h4 MsnE-Dm clr-bl'>Luas Bangunan ({props.tipe.lb.v} m<sup>2</sup>)</p>
									<p className='h4 MsnE-Dm clr-yl mb-4'>{props.tipe.lb.p} x {props.tipe.lb.l} m</p>
									<a name="banner-detail" id="banner-detail" className="btn align-self-baseline MsnE-EBd clr-bl text-uppercase mb-5" href="https://wa.me/6281337852020?text=Halo%20GIP,%20bisa%20minta%20info%20lebih%20lanjut?" role="button" target='_blank' style={{ background: 'var(--yellow)', fontSize: '1.5rem' }}>
										<img src={back} style={{ width: '16px', height: '16px', marginRight: '.5rem', transform: 'rotate(180deg)' }} />
										Hubungi Kami
									</a>
								</div>
							</div>
							<div className='divd-yellow mt-3'></div>
							<p className='mt-5 h5 MsnE-Dm clr-bl ltr-spc-25'>Ketersediaan</p>
							<p className='h5 my-5 text-center MsnE-Dm clr-bl ltr-spc-25'>DISTRICT TETRA - BLOK {props.tipe.blok} {props.tipe.no}</p>
							<img src={props.tipe.siteplan} className='w-100'></img>
						</div>

					</div>
				</div>
			</div>
		</>
	)
}

export default function Unit() {
	var base = new Base()

	const [test] = useState('123')
	const [arrBanner, setArrBanner] = useState([])
	const [arrBannerMobile, setArrBannerMobile] = useState([])
	const [items, setItems] = useState(['hello', 'world', 'click', 'me'])
	const [bannerMobile, setBannerMobile] = useState({})
	const [bannerMobileIndex, setBannerMobileIndex] = useState(-1)
	const [inProp, setInProp] = useState(false);

  const nodeRef = useRef(null);

	useEffect(()=>{
	  get_data_banner()
	}, [])

	useEffect(()=>{
		console.log(bannerMobile.subtitle)
	}, [bannerMobile])

	function handleAdd() {
    const newItems = items.concat([
      prompt('Enter some text')
    ]);
    setItems(newItems)
  }

  function handleRemove(i) {
    var newItems = JSON.parse(JSON.stringify(items))
    newItems.splice(i, 1);
		console.log(newItems)
    // setItems(newItems)
  }

	async function get_data_banner(){
    var response = await base.request("/unit/all")

    if(response.status === "success"){
			var arr = []
			var arr_mobile = []
			for(let x in response.data){
				response.data[x].image = base.url_image + "/unit?file_name=" + response.data[x].file_name
				if(response.data[x].type == "banner_unit_desktop")
					arr.push(response.data[x])
				else if(response.data[x].type == "banner_unit_mobile"){
					if(arr_mobile.length == 0)
						setBannerMobile(response.data[x])
					arr_mobile.push(response.data[x])
				}
			}
			setArrBanner(arr)
			setArrBannerMobile(arr_mobile)
    }
  }

	function onSlideChange(index){
		console.log(index)
		setBannerMobile(arrBannerMobile.length > 0 ? arrBannerMobile[index] : {})
		setBannerMobileIndex(index)
	}

	return (
		<>
			<section id='banner'>
				{/* <div className="banner-section d-flex" style={{ minHeight: '100vh', backgroundImage: `url(${img001})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
					<div className='container d-flex'>
						<div className='d-flex flex-column align-self-center'>
							<div className='d-inline-flex flex-column align-self-center p-4' style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
								<p className='h5 text-white' style={{ fontFamily: 'maison-neue-ext-book' }}>Rekomendasi Unit</p>
								<h1 className='display-4 text-uppercase MsnE-Dm clr-l-bl'>District Tetra</h1>
								<p className='h5 MsnE-Bk' style={{ color: 'hsl(0,0%,70%)' }}>Ready Unit</p>
								<p className='h5 MsnE-Bk text-white' style={{ maxWidth: '37ch', lineHeight: 1.3 }}>Unit Ready District Tetra merupakan Produk Gudang
									unggulan kami yang memiliki Tampilan Fasad Modern
									berkonsep “American Warehouse”</p>
							</div>
							<div class="d-grid gap-2 mt-4">
								<a name="banner-detail" id="banner-detail" class="btn ml-2 MsnE-EBd clr-bl text-uppercase" href="#" role="button" style={{ background: '#FBB034', fontSize: '1.5rem' }}>View Detail</a>
							</div>
						</div>
					</div>
				</div> */}
				{
					bannerMobile.subtitle != null ?
					<div className="d-block d-lg-none">
						<div className='container d-flex justify-content-center'>
							<div className='d-flex flex-column align-self-center scaleMdLowHalf'>
								<div className='d-inline-flex flex-column align-self-center p-4' style={{ backgroundColor: 'rgba(0,0,0,0.3)', minHeight: '170px'}}>
									{/* <CSSTransition nodeRef={nodeRef} in={bannerMobileIndex} timeout={1000} classNames="my-node"> */}
										<p ref={nodeRef} className='h5 text-white' style={{ fontFamily: 'maison-neue-ext-book' }}>{bannerMobile.subtitle != null ? bannerMobile.subtitle : ''}</p>
									{/* </CSSTransition>
									<CSSTransition nodeRef={nodeRef} in={bannerMobileIndex} timeout={1000} classNames="my-node"> */}
										<h1 className='display-4 text-uppercase MsnE-Dm clr-l-bl'>{bannerMobile.title != null ? bannerMobile.title : ''}</h1>
									{/* </CSSTransition>
									<CSSTransition nodeRef={nodeRef} in={bannerMobileIndex} timeout={1000} classNames="my-node"> */}
										<p className='h5 MsnE-Bk' style={{ color: 'hsl(0,0%,70%)' }}>{bannerMobile.subcontent != null ? bannerMobile.subcontent : ''}</p>
									{/* </CSSTransition>
									<CSSTransition nodeRef={nodeRef} in={bannerMobileIndex} timeout={1000} classNames="my-node"> */}
										<p className='h5 MsnE-Bk text-white' style={{ maxWidth: '37ch', lineHeight: 1.3 }}  dangerouslySetInnerHTML={{ __html: bannerMobile.content != null ? bannerMobile.content : '' }}></p>
									{/* </CSSTransition> */}
								</div>
								<div class="d-flex gap-2 mt-4 justify-content-center">
									<a name="banner-detail" id="banner-detail" class="btn ml-2 MsnE-EBd clr-bl text-uppercase" href="#" role="button" style={{ background: '#FBB034', fontSize: '1.5rem' }}>View Detail</a>
								</div>
							</div>
						</div>
						
						<div className="container">
							<div className="row">
								<div className="col p-0" style={{marginTop: '-2rem'}}>
									<Swiper
										slidesPerView={1}
										loop={true}
										// autoplay={{
										// 	delay: 3000,
										// 	// disableOnInteraction: false
										// }}
										onSlideChange={({
											activeIndex,
											snapIndex,
											previousIndex,
											realIndex,
										}) => onSlideChange(realIndex)}
										modules={[Autoplay]}
									>
										{
											arrBannerMobile.map((data, index) => (
												<SwiperSlide>
													{/* <div className="banner-section d-flex" style={{ width: '100vw', aspectRatio: '360/375', backgroundImage: `url(${data.image})`, backgroundRepeat: 'no-repeat', backgroundSize: '70%', backgroundPosition: '15% 50%' }}>
													</div> */}
													<div className="" style={{aspectRatio: '360/375', backgroundImage: `url(${data.image})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
													</div>
												</SwiperSlide>
											))
										}
										{/* <SwiperSlide>
											<div className="banner-section d-flex" style={{ width: '100vw', aspectRatio: '360/375', backgroundImage: `url(${img001})`, backgroundSize: '180%', backgroundPosition: '15% 50%' }}>
											</div>
										</SwiperSlide>
										<SwiperSlide>
											<div className="banner-section d-flex" style={{ width: '100vw', aspectRatio: '360/375', backgroundImage: `url(${img003_mobile})`, backgroundSize: 'cover' }}>
											</div>
										</SwiperSlide> */}
									</Swiper>
								</div>
							</div>
						</div>
						<img src={accent} alt="accent" style={{position: 'absolute', top: '97px', right: '0', zIndex: '1'}}/>
					</div>
					:
					<div className='mt-5 pt-5 pt-lg-0'></div>
				}
				
				{
					arrBanner.length > 0 ?
					<div className="d-none d-lg-block">
						<Slider arr={arrBanner}/>
					</div>
					:
					<div className='mt-5 pt-5 pt-lg-0'></div>
				}
				
			</section>

			{/* <section>
				<div className="block">
					<div className="container">
						<div className="about-us">
							<h3 className="heading-title clr-yl">About Us</h3>
							<p className='text-white' style={{ lineHeight: '2' }} dangerouslySetInnerHTML={{ __html: txt.about_us }}></p>
						</div>
					</div>
				</div>
			</section> */}
			
				<section style={{ paddingTop: '6rem' }}>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-6 p-0 py-5 pl-5 d-flex align-items-center' style={{ borderLeft: '3px solid var(--yellow)' }}>
								{/* <h2 className='text-white MsnE-EBd' style={{ maxWidth: '20ch' }}>Kawasan Industri Pergudangan Modern di Gedangan dengan Pengembangan Lahan seluas  <span className='display-1' style={{ color: 'var(--yellow)', fontFamily: 'maison-neue-ext-extra-bold', position: 'absolute' }}>60ha.</span></h2> */}
								<h2 className='text-white MsnE-EBd' style={{ maxWidth: '20ch' }}>Kawasan Industri Pergudangan Modern di Gedangan</h2>
							</div>
							<div className='col-lg-6 p-0 pt-5 pt-lg-0'>
								<div style={{ maxHeight: '400px', width: '100%' }}>
									<img src={img002} alt="" style={{ width: '100%' }} />
								</div>
							</div>
						</div>
					</div>
				</section>
			

			<section>
				<div className="block">
					<div className="container no-pd">
						<div className="title">
							<h3 className="heading-title mb-3 MsnE-EBd clr-yl">Why Gedangan Industrial Park</h3>
							<p className='text-white'>terdepan di lokasi - terjamin di legalitas - terbaik di spesifikasi</p>
						</div>
						<div className="our-services">
							<div className="row">
								<Why why={txt.why._1} />
								<Why why={txt.why._2} />
								<Why why={txt.why._3} />
								<Why why={txt.why._4} />
							</div>
						</div>
					</div>
				</div>
			</section>


			<section>
				<div className="container">
					<div style={{ backgroundColor: 'rgba(0,0,0,0.3)', padding: '10%' }}>
						<p className='text-center h3 text-white' style={{ lineHeight: '1.4' }}>{txt.quote}</p>
					</div>
				</div>
			</section>


			<section>
				<div className='container' style={{ marginTop: '7.5rem' }}>
					<div style={{ aspectRatio: '9/4', backgroundImage: `url(${img001})`, backgroundSize: 'cover', backgroundPosition: '50% 80%' }}>
					</div>
				</div>
			</section>

			<section>
				<div className='container d-flex flex-column' style={{ marginTop: '7.5rem' }}>
					<div className='d-flex justify-content-center'>
						<img src={tetra} style={{ maxWidth: '45%', height: 'auto' }} />
					</div>
					<div className='align-self-center mt-5' style={{ width: '80%', height: '6px', backgroundColor: 'var(--yellow)' }}>
					</div>
					<div className='row mt-5'>
						<Tipe tipe={txt.tipe._1} />
						<Tipe tipe={txt.tipe._2} />
						<Tipe tipe={txt.tipe._3} />
						<Tipe tipe={txt.tipe._4} />
					</div>
				</div>
			</section>

			<section>
				<div className='container' style={{ marginTop: '7.5rem', marginBottom: '7.5rem' }}>
					<div className='d-flex justify-content-center'>
						<img src={siteplan} style={{ maxWidth: '100%', height: 'auto' }}></img>
					</div>
				</div>
			</section>
			<Tipe_Modal tipe={txt.tipe._1} />
			<Tipe_Modal tipe={txt.tipe._2} />
			<Tipe_Modal tipe={txt.tipe._3} />
			<Tipe_Modal tipe={txt.tipe._4} />
		</>
	)
}